<script>
// import Datepicker from "vue3-datepicker";
// import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ref } from "vue";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import { Api } from "../../../helpers";
import { Erreur } from "../../../helpers/error";
import Swal from "sweetalert2";

/**
 * Task-create component
 */
export default {
  page: {
    title: "Create Task",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    // Datepicker,
    // ckeditor: CKEditor.component,
    Layout,
    PageHeader,
    QuillEditor,
  },
  data() {
    return {
      spinner: false,
      titre: "",
      process: false,
      progress: "",
      tagList: [],
      categories: [],
      tags: [],
      enterKeyWords: "",
      keyWords: [],
      article: {
        title: "",
        content: "",
        author: "",
        categ: "",
        tag: "",
        description: "",
      },
      content: "",
      title: "Modifier un article",
      items: [
        {
          text: "Articles",
          href: "/blog/list",
        },
        {
          text: "Détails",
          href: `/blog/detail/${this.$route.params.id}`,
        },
        {
          text: "Modifier",
          active: true,
        },
      ],
      daterange: ref(new Date()),
      editor: ClassicEditor,
      teamMember: [{ id: 1 }],
    };
  },
  methods: {
    // Add a tag to the list of tags
    addTags(event) {
      // Prevent the default form submission behavior
      event.preventDefault();

      // Get the value of the input and trim any whitespace
      const val = event.target.value.trim();

      // If the input has a value and the tag is not already in the list
      if (val.length > 0 && !this.tags.includes(val)) {
        // Add the tag to the list
        this.tags.push(val);
        // Clear the input field
        event.target.value = "";
      }
    },

    // Remove a tag from the list of tags
    removeTags(index) {
      // Remove the tag at the specified index
      this.tags.splice(index, 1);
    },

    // Remove the last tag if the input field is empty
    removeLastTag(event) {
      if (event.target.value.length === 0) {
        // Remove the last tag from the list
        this.removeTags(this.tags.length - 1);
      }
    },

    addKeywords(event) {
      event.preventDefault();

      const val = event.target.value.trim();

      if (val.length > 0 && !this.keyWords.includes(val)) {
        this.keyWords.push(val);
        event.target.value = "";
        this.enterKeyWords = "";
      }
    },

    removeKeywords(index) {
      this.keyWords.splice(index, 1);
    },

    removeLastKeywords(event) {
      if (event.target.value.length === 0) {
        this.removeKeywords(this.keyWords.length - 1);
      }
    },

    imgVideoSelect(event) {
      this.imgSelect = event.target.files[0];
    },

    imgVideoSelectPaysage(event) {
      this.imgPaysage = event.target.files[0];
    },

    updateArticle() {
      this.spinner = true;
      // this.progress = true;
      this.progressValue = 0;
      this.process = true;
      this.modalOk = false;
      const onUploadProgress = (progressEvent) => {
        const progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        this.progress = Math.min(progress, 98); // Limite la valeur maximale à 98
        console.log(`Upload progress: ${this.progress}% et ${progress}`);
      };
      if (this.imgPaysage || this.imgSelect) {
        let keyWordsString = this.keyWords.join(',');
        Api.putFormData(
          `/actualite/rest/article/update-article/${this.$route.params.id}?&tags=${this.tags}&titre=${this.article.title}`,
          {
            autheur: this.article.author,
            content: this.article.content,
            description: this.article.description,
            imagepaysage: this.imgPaysage,
            imageportrait: this.imgSelect,
            keyword: keyWordsString,
          },
          onUploadProgress
        )
          .then(function () {
            this.spinner = false;
            Swal.fire("Success!", "Article enregistré", "success");
          })
          .catch((error) => {
            this.spinner = false;
            this.progress = 0;
            Erreur.gestionErreur(error.message);
          });
      }
      Api.putFormData(
        `/actualite/rest/article/update-article-content/${this.$route.params.id}`,
        {
          content: this.article.content,
        },
        onUploadProgress
      )
        .then(function () {
          Swal.fire("Success!", "Article enregistré", "success");
          this.spinner = false;
        })
        .catch((error) => {
          this.process = false;
          this.progress = 0;
          Erreur.gestionErreur(error.message);
        });
    },
  },

  mounted() {
    this.spinner = true;
    const onUploadProgress = (progressEvent) => {
      this.progress = Math.round(
        (progressEvent.loaded / progressEvent.total) * 100
      );
      console.log(`Upload progress: ${this.progress}%`);
    };
    Api.get(`/actualite/rest/article/${this.$route.params.id}`, {
      onUploadProgress,
    })
      .then((res) => {
        this.articles = res.data.content;
        this.article.title = this.articles.titre;
        this.article.author = this.articles.autheur;
        this.article.categ = this.articles.categorie.name;
        this.article.content = this.articles.content;
        this.article.description = this.articles.description;

        const keyWordsList = this.articles.keywords || ""; // Si keywords est null, assigner une chaîne vide
        this.keyWords = keyWordsList
          .split(",")
          .map((keyword) => keyword.trim());

        var allTags = this.articles.tags;
        allTags.forEach((element) => {
          this.tags.push(element.name);
        });
        this.spinner = false;
      })
      .catch((error) => {
        Erreur.gestionErreur(error.message);
      });

    Api.get("/actualite/rest/categories/all", onUploadProgress)
      .then((res) => {
        this.categories = res.data.content;
      })
      .catch((error) => {
        Erreur.gestionErreur(error.message);
      });

    Api.get("/actualite/rest/tags/all", onUploadProgress)
      .then((res) => {
        this.tagList = res.data.content;
      })
      .catch((error) => {
        Erreur.gestionErreur(error.message);
      });
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-10">
        <button type="submit" @click="updateArticle" class="btn c2play-primary">
          <span v-if="spinner == false">Modifier l'article</span>
          <b-spinner
            v-if="spinner == true"
            variant="success"
            role="status"
          ></b-spinner>
        </button>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Modifier un article</h4>
            <form class="outer-repeater">
              <div data-repeater-list="outer-group" class="outer">
                <div data-repeater-item class="outer">
                  <div class="row">
                    <div class="form-group col-lg-6 mb-2">
                      <label for="taskname" class="col-form-label">Titre</label>
                      <div class="col-lg-12">
                        <input
                          id="taskname"
                          name="taskname"
                          type="text"
                          class="form-control"
                          placeholder="Saisissez le titre de l'article"
                          v-model="article.title"
                        />
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <label class="col-form-label">{{
                        $t("pages.videos.formulaire.categ.label")
                      }}</label
                      ><span style="color: red; font-size: 1.2em">*</span>
                      <div class="col-md-12">
                        <select class="form-control" v-model="article.categ">
                          <option value="">
                            {{
                              $t("pages.videos.formulaire.categ.placeholder")
                            }}
                          </option>
                          <option
                            v-for="categ in categories"
                            :key="categ.id"
                            :value="categ.name"
                          >
                            {{ categ.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="mb-2 row">
                    <div class="col-lg-6">
                      <label class="col-form-label">{{
                        $t("pages.videos.formulaire.tags.label")
                      }}</label>
                      <div class="form-control">
                        <div
                          v-for="(tag, index) in tags"
                          :key="index"
                          class="tags-input_tag c2play-primary"
                        >
                          <span @click="removeTags(index)"
                            ><i class="mdi mdi-close-thick"></i
                          ></span>
                          {{ tag }}
                        </div>
                        <select
                          @keydown="addTags"
                          @keydown.delete="removeLastTag"
                          class="tags-input_text"
                          @change="addTags"
                        >
                          <option value="">
                            {{ $t("pages.videos.formulaire.tags.placeholder") }}
                          </option>
                          <option
                            aria-placeholder="okokok"
                            v-for="tagL in tagList"
                            :key="tagL.id"
                            :value="tagL.name"
                          >
                            {{ tagL.name }}
                          </option>
                        </select>
                        <!-- <input type="text" :placeholder="$t('pages.videos.formulaire.tags.placeholder')" @keydown="addTags" @keydown.delete="removeLastTag" class="tags-input_text"> -->
                      </div>
                    </div>

                    <div class="form-group col-lg-6 mb-2">
                      <label for="taskname" class="col-form-label"
                        >Auteur</label
                      >
                      <div class="col-lg-12">
                        <input
                          id="taskname"
                          name="taskname"
                          type="text"
                          class="form-control"
                          placeholder="Saisissez le nom de l'auteur de l'article"
                          v-model="article.author"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="form-group col-lg-6 mb-2">
                      <label for="taskname" class="col-form-label"
                        >Image Portrait</label
                      >
                      <div class="col-lg-12">
                        <input
                          id="taskname"
                          name="taskname"
                          type="file"
                          class="form-control"
                          placeholder="Enter Task Name..."
                          @change="imgVideoSelect($event)"
                        />
                      </div>
                    </div>

                    <div class="form-group col-lg-6 mb-2">
                      <label for="taskname" class="col-form-label"
                        >Image Paysage</label
                      >
                      <div class="col-lg-12">
                        <input
                          id="taskname"
                          name="taskname"
                          type="file"
                          class="form-control"
                          placeholder="Enter Task Name..."
                          @change="imgVideoSelectPaysage($event)"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="form-group row">
                    <div class="col-lg-6">
                      <label class="col-form-label">Mots clés</label>
                      <div class="form-control">
                        <div
                          v-for="(tag, index) in keyWords"
                          :key="index"
                          class="tags-input_tag c2play-primary"
                        >
                          <span @click="removeKeywords(index)">
                            <i class="mdi mdi-close-thick"></i>
                          </span>
                          {{ tag }}
                        </div>

                        <input
                          @keydown.enter.prevent="addKeywords"
                          @keydown.delete="removeLastKeywords"
                          id="taskname"
                          name="taskname"
                          class="tags-input_text"
                          type="text"
                          placeholder="Ajoutez un mot clé"
                          v-model="enterKeyWords"
                        />
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <label for="projectdesc" class="col-form-label">{{
                        $t("pages.categorie.formulaire.description.label")
                      }}</label>
                      <div class="col-lg-12">
                        <textarea
                          id="projectdesc"
                          class="form-control"
                          rows="3"
                          v-model="article.description"
                          :placeholder="
                            $t(
                              'pages.categorie.formulaire.description.placeholder'
                            )
                          "
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <div class="form-group row mb-4">
                    <label class="col-form-label">Contenu</label>
                    <div class="col-lg-12">
                      <QuillEditor
                        v-model:content="article.content"
                        toolbar="full"
                        content="html"
                        contentType="html"
                        theme="snow"
                      />
                      <!-- <ckeditor :editor="editor" v-model="content"></ckeditor> -->
                    </div>
                  </div>
                  <br /><br />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
